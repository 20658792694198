@tailwind base;
@tailwind components;
@tailwind utilities;

details > summary::-webkit-details-marker {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

@layer base {
  p {
    @apply sm:text-lg mb-5;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .sm-prose p {
    @apply text-base;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1,
  h2,
  h3 {
    @apply font-serif;
  }

  h1 {
    @apply text-4xl leading-tight;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl lg:text-4xl;
  }
  h4 {
    @apply text-2xl lg:text-3xl;
  }
  h5 {
    @apply text-xl lg:text-2xl;
  }
  h6 {
    @apply text-lg lg:text-xl;
  }

  /* * * * * * * * * * * * *
   * New heading styles
   * @TODO: Remove the ".new-hx" classes and set them as the default "hx" rules - PER-1450
   * * * * * * * * * * * * */
  h3.new-h3 {
    @apply text-2xl lg:text-4xl;
  }
  h6.new-h6 {
    @apply text-base md:text-[1.313rem];
  }
  /* * * * * * * * * * * * * * * * * * * * * */

  html {
    @apply text-gray-900 antialiased;
  }
  strong {
    @apply font-bold;
  }
  a {
    @apply font-semibold;
  }
  textarea {
    resize: none;
  }

  hr {
    @apply border-gray-500;
  }
}

@layer utilities {
  .bg-blue-gradient {
    @apply text-white;
    background: linear-gradient(
      320.58deg,
      #1755d1 -1.05%,
      #2264e9 6.18%,
      #0c2c6d 56.83%
    );
  }

  /* These classes help with adding ellipses after a certain number of lines. */
  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis !important;
  }
  .line-clamp-none {
    display: block;
    -webkit-line-clamp: inherit;
    -webkit-lbox-orient: inherit;
  }

  .grid-bottom-border > div {
    @apply border-b border-gray-100 pb-4;
  }

  .pb-safe {
    padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
  }

  .swarm-dot-highlight {
    @apply bg-blue-600 text-white animate-glow-pulse;
  }

  .swarm-dot-border {
    @apply outline outline-2 outline-blue-600;
  }

  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .leading-zero {
    line-height: 0px;
  }
}

@layer components {
  /* These are for tables coming from the Contentful rich-text editor */
  .content-table {
    @apply w-full my-12 table-auto;
  }
  .content-table th {
    @apply bg-blue-600 text-left p-4 text-white;
  }
  .content-table th p {
    @apply text-white font-semibold;
  }
  .content-table th:last-child {
    @apply rounded-tr-lg;
  }
  .content-table th:first-child {
    @apply rounded-tl-lg;
  }
  .content-table th .para-2 {
    @apply text-white;
  }
  .content-table td {
    @apply p-4;
  }
  .content-table .para-2 {
    @apply my-0;
  }
  .content-table tr:nth-child(odd) {
    @apply bg-stone-100;
  }

  .content-list {
    @apply mb-4;
  }

  .content-list li p {
    @apply mb-0;
  }

  .content-list li {
    @apply ml-2 sm:ml-5;
  }

  ol.content-list > li > ol {
    list-style-type: upper-alpha;
  }

  ol.content-list > li > ol > li > ol {
    list-style-type: lower-roman;
  }

  ol.content-list ul,
  ol.content-list ol {
    @apply mb-0;
  }

  .content-faq-text p {
    @apply text-gray-900 text-sm;
  }

  /* Use these for places where we use raw markup from some other source, e.g. question explainers */
  .content-wrapper ul {
    @apply ml-4 mb-4;
  }

  .content-wrapper ul li {
    @apply list-disc;
  }

  .content-wrapper {
    @apply sm:text-lg;
  }

  /**
  * This is here because occasionally we need to apply it to Headless UI components.
  */
  .btn-text {
    @apply text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-1 font-semibold flex items-center gap-1;
  }

  /**
   * Link styles
   */
  .footer-link-list li a {
    @apply text-gray-400 hover:text-gray-500 text-sm leading-8;
  }

  /**
   * Container classes.
   */
  .container-toc {
    @apply container max-w-[1296px] px-2 xl:px-8 xl:grid xl:grid-cols-toc-side gap-8;
  }

  /**
   * DEPRECATED: A heading component for some of our static pages.
   */
  .landing-page-heading {
    @apply text-2xl text-center md:text-4xl;
  }

  /**
   * Spacing classes.
   * DEPRECATED - Do not use, replace when you can.
   */
  .spacing-xs {
    @apply my-2;
  }
  .spacing-sm {
    @apply my-4;
  }
  .spacing-md {
    @apply my-8;
  }
  .spacing-semi-lg {
    @apply my-12;
  }
  .spacing-lg {
    @apply my-16;
  }
  .spacing-xl {
    @apply my-32;
  }

  /**
  * ==============================================
  * Dot Flashing
  * ==============================================
  * See https://codepen.io/nzbin/pen/GGrXbp
  */
  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      @apply bg-white;
    }
    50%,
    100% {
      @apply bg-gray-400;
    }
  }

  .hide-scroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .small-caps {
    font-variant: all-small-caps;
    letter-spacing: 0.7px;
  }

  .font-variant-normal {
    font-variant: normal;
  }

  /**
   * Tooltip arrows
   */
  .tooltip {
    @apply absolute z-10 p-2 leading-4 text-sm font-medium bg-white rounded-lg shadow-lg transition-opacity duration-300  w-52 after:content-[''] after:absolute after:left-1/2  after:-translate-x-1/2 after:border-8 after:border-x-transparent;
  }

  .tooltip--arrow-middle {
    @apply after:left-1/2;
  }

  .tooltip--arrow-left {
    @apply after:left-[10%];
  }

  .tooltip--arrow-right {
    @apply after:left-[90%];
  }

  .tooltip--arrow-top {
    @apply after:border-t-transparent after:border-b-white after:bottom-[100%];
  }

  .tooltip--arrow-bottom {
    @apply after:border-b-transparent after:border-t-white after:top-[100%];
  }

  /**
   * Contentful Markdown (for scenario pages)
   *
   * We programmatically add some markdown fields into Contentful from a spreadsheet.
   * These classes help us style that.
   */
  .scenario-criteria li {
    @apply my-3 -indent-8 list-none;
  }

  .scenario-criteria li::before {
    content: url(../../public/images/icons/list-item-checkbox.png);
    @apply relative top-1 pr-3;
  }

  .scenario-criteria ul {
    @apply pl-8;
  }

  .see-more-overlay::before {
    content: "";
    z-index: 10;
    width: 100%;
    height: 50px;
    display: block;
    position: absolute;
    bottom: 80px;
    background: linear-gradient(
      0deg,
      rgb(255, 255, 255, 1),
      rgb(255, 255, 255, 0)
    );
  }
}

/**
 * These two classes are the same effect. The -filter one uses a filter so that
 * it can work in the SVG world. They are used for the tiny product icons.
 */
.marker-drop-shadow-filter {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.12));
}

.marker-drop-shadow {
  @apply shadow-[0px_4px_8px_rgba(0,0,0,0.12)];
}
